import {useEffect, useState, useRef} from "react";
import ReactDOM from 'react-dom';
import {Constants, getBackendUrl} from "../util/Constants";
import {getCookie, setCookie, deleteCookie} from 'cookies-next';
import {useRouter} from 'next/router'
import {fetchData, getGlossaryLanguage, isItUndefined, isMobile, isTablet} from "../util/DataUtil";
import { 
    autoPopulateAddActive, 
    handleFocus, 
    translateElements, 
    handleChange, 
    enableMarketoButton,
    translatePlaceholders
}
from "../util/MarketoUtil";
import {setMarketoCookieRedux} from "../store/marketoCookieSlice";
import {setVideoPlayingStateRedux} from "../store/gatedVideoPlayingStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { cleanHTML } from "./utils/htmlUtils";
import CustomDropdown from "./form/custom-dropdown";

const marketoScriptId = 'mktoForm';
// const fieldIds = [
//     '#Company_HQ_Country__c',
//     '#Company_HQ_State__c',
// ];

export default function Marketo({ children, close, global, ctaPopUp, setShowDownloadItems, lang, seIsFormSubmitted, isTemplateInfo = false, globalWidth = null }) {
    const formRef = useRef(null);
    const {asPath} = useRouter();
    let alias = asPath.substring(1);
    const [glossary, setGlossary] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmittedLocal, setIsSubmittedLocal] = useState(false);
    const [firstName, setFirstName] = useState(false);
    const [email, setEmail] = useState(false);
    const [marketoCookie, setMarketoCookie] = useState(false);
    const [checked, setChecked] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [marketoFolderId, setMarketoFolderId] = useState('');
    const [marketoProgram, setMarketoProgram] = useState('');
    const marketoCookieState = useSelector(setMarketoCookieRedux);
    const videoPlayingState = useSelector(setVideoPlayingStateRedux);
    const dispatch = useDispatch();
    const [isItGated, setIsItGated] = useState(false);
    const [isItRegisterLead, setIsItRegisterLead] = useState(false);
    const [isItContactUs, setIsItContactUs] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);
    const [customDropdownValue, setCustomDropdownValue] = useState({});
    // const host = getBackendUrl(useSelector((state) => state.host.host));
    const [areDropdownFieldsReplaced, setAreDropdownFieldsReplaced] = useState(false);


    let formId = isItUndefined(children?.field_marketo_form_ids);
    let formToken = isItUndefined(global?.field_marketo_form_token);
    let formUrl = isItUndefined(global?.field_marketo_form_url);
    let downloadButtonText = isItUndefined(children?.field_marketo_download_button);
    
    let templateTitle = '';

    if(typeof document !== "undefined"){
        let item = document.querySelector('.gated_template .template-title .top h1');
        templateTitle = item?.innerText;
    }

    if(typeof window !== 'undefined'){
        var host = getBackendUrl(window.location.host); 
    }

    useEffect(() => {
        if (formRef.current && (isItRegisterLead || isItContactUs)) {
            const mktoRows = document.querySelectorAll("form .mktoFormRow");
            const firstForm = document.querySelector('#register-lead form');
            let formWidth = 0;

            if (firstForm) {
                formWidth = firstForm.offsetWidth;
            }

            formRef.current.setValues({
                [customDropdownValue?.name]: customDropdownValue?.value
            });

            handleCustomChangeLogic(mktoRows, formWidth, glossary, lang);
        }
    }, [customDropdownValue]);

    const fetchDataSecond = async () => {
        try {
          const response = await fetchData(host, "glossaries/v1/" + getGlossaryLanguage(lang), true);
          return response;
        } catch (error) {
          console.error('fetchDataSecond error:', error);
          return null;
        }
      };

      useEffect(() => {
        const fetchGlossaryData = async () => {
          if (lang !== 'en' && lang !== undefined) {
            try {
              const data = await fetchDataSecond();
              setGlossary(data || []);
            } catch (error) {
              console.error('Error fetching glossary data:', error);
              setGlossary([]);
            }
          } else {
            setGlossary([]);
          }
        };
      
        fetchGlossaryData();

        if(typeof document !== "undefined"){
            // let gated = document.querySelector('.gated_template');
            let registeLead = document.querySelector('#register-lead');
            let contactUs = document.querySelector('.contact-us-form')
            // if(gated !== null){
            //     setIsItGated(true)
            // }
            if(registeLead !== null){
                setIsItRegisterLead(true);
            }

            if(contactUs !== null && formId === '2787'){
                setIsItContactUs(true)
            }
        }
    }, [lang])

    useEffect(() => {
        const marketoIsSet = getCookie('marketoissetondlr');
        const marketoVals = getCookie('marketovals');

        if(marketoIsSet) {
            setIsSubmitted(marketoIsSet);
        }else {
            deleteCookie('_mkto_trk');
        }
    
        if(marketoVals) {
            setMarketoCookie(JSON.parse(marketoVals));
        }
    }, [isSubmitted]);
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            let form = window.MktoForms2?.getForm(formId);

            if(marketoCookieState.payload.marketoCookieRedux.marketoCookieRedux.value !== undefined){
                form?.vals(marketoCookieState.payload.marketoCookieRedux.marketoCookieRedux.value)
            }
        }
    }, [marketoCookieState])

    useEffect(() => {
        let fields = [];
        fields.push(document.querySelectorAll('form .mktoField'));

        autoPopulateAddActive(fields)
    })

    const updateMktoRows = (mktoRows, formWidth) => {                
        // const errorMsg = mktoRows[0].querySelector('.mktoErrorMsg');
        // errorMsg.style.display = 'none';
        mktoRows.forEach(element => {
            element.classList.add('noField');

            const firstChild = element.firstElementChild;
            const label = element.querySelector('[id*="LblmktoCheckbox"]');
            const textarea = element.querySelector('textarea');
            const sectionTitle = element.querySelector('.mktoHtmlText');
            const checkboxElement = element.querySelector('input[type="checkbox"]');
            const labelsWithAsterix = element.querySelectorAll('.mktoRequiredField label');                        
            if(labelsWithAsterix){                
                labelsWithAsterix.forEach(function(lbl){
                    lbl.textContent = lbl.textContent.replace(/\*/g, '');                    
                })
            }

            if (firstChild && (firstChild.classList.contains('mktoPlaceholder') || (firstChild.tagName === 'INPUT' && firstChild.type === 'hidden'))) {
                element.classList.add('noField');
            } else {
                element.classList.remove('noField');
            }

            // if (label) {
            //     label.setAttribute('style', `width: ${formWidth}px !important;`);
            // }

            if (textarea) {
                // element.setAttribute('style', `width: ${formWidth}px !important;`);
                firstChild.setAttribute('style', `width: 100%;`);
            }

            if(sectionTitle){
                sectionTitle.classList.add('sectionTitle');
                element.classList.add('noBorder');
            }

            if(checkboxElement){
                element.classList.add('noBorder');
                element.classList.add('checkboxMargin');
            }
        });
    };

    const updatePrivacyText = (mktoRows) => {
        mktoRows.forEach(element => {
            const firstChild = element.firstElementChild;

            if(element.classList.contains('noBorder')){
                element.classList.add('privacyText');
                // firstChild.setAttribute('style', `width: 100%;`);
            }
        });
    }

    const handleAddEventListener = () => {
        let fields = [];
        fields.push(document.querySelectorAll('form .mktoField'));

        fields[0].forEach(item => {
            item.addEventListener("input", function(event) {
                handleChange(formId, event);
                // handleSetDropdownValue(formId, customDropdownValue);
            });
        });
    }

    const handleAddHoverEffect = (mktoRows) => {
        mktoRows.forEach(element => {
            const inputs = element.querySelectorAll('input, select, textarea');

            if (inputs.length > 0) {
                Array.from(element.children).forEach(child => {
                    const inputChild = child.querySelector('input');

                    // Add hover effect only if the input is not a checkbox
                    if (!inputChild || inputChild.type !== 'checkbox') {
                        child.classList.add('addHover');
                    }
                });
            }
        });
    };

    // Function to observe changes in the text content of an element (both label and options)
    const observeTextChange = (element, callback) => {
        if (!element) return;

        // Set up a MutationObserver to watch for changes in the element's text
        const observer = new MutationObserver((mutations) => {
            let changeDetected = false; // Flag to prevent multiple updates

            mutations.forEach((mutation) => {
                if ((mutation.type === 'childList' || mutation.type === 'characterData') && !changeDetected) {
                    // When text changes, execute the callback with the updated text
                    changeDetected = true; // Prevent further execution
                    callback(element.innerText || element.textContent);
                    observer.disconnect(); // Stop observing after capturing the change
                }
            });
        });

        // Start observing the element for changes
        observer.observe(element, { childList: true, characterData: true, subtree: true });
    };

    // Function to safely get text from an element with fallback
    const getSafeText = (element) => {
        return element ? element.innerText || element.textContent || '' : '';
    };

    // Optimized function to replace dropdown fields with custom components
    const replaceDropdownFields = (mktoRows) => {
        mktoRows.forEach((row) => {
            let dropdown = row.querySelector('select');
            if (!dropdown) {
                if (document.getElementById('Racks_Required__c')) {
                    dropdown = document.getElementById('Racks_Required__c');
                }
                else {
                    return;
                }
            }else if(dropdown?.id === 'Cloud_Connect__c') {
                return
            }
            const label = document.querySelector(`label[for="${dropdown.id}"]`);
            const fieldId = dropdown?.id;
            const options = dropdown.options;
            const parentRow = dropdown.closest('.mktoFormRow');
            let optionsProps = [];
            let hideElement = dropdown.closest('.mktoFieldDescriptor');
    
            // Hide the original dropdown descriptor if present
            if (hideElement) {
                hideElement.style.display = 'none';
            }
    
            // Prepare the options for the custom dropdown
            for (let i = 0; i < options?.length; i++) {
                const option = options[i];
                optionsProps.push({ id: i, label: getSafeText(option), value: option.value });
    
                // Observe changes in the option's text for translations
                observeTextChange(option, (translatedOptionText) => {
                    if (translatedOptionText && translatedOptionText !== optionsProps[i].label) {
                        optionsProps[i].label = translatedOptionText;
                    }
                });
            }
    
            // Create a container for the custom dropdown if it doesn't exist
            if (parentRow) {
                let customContainer = document.getElementById(`custom-selector-${fieldId}`);
    
                if (!customContainer) {
                    customContainer = document.createElement('div');
                    customContainer.classList.add('mktoFormRow', 'custom-selector-container');
                    customContainer.id = `custom-selector-${fieldId}`;
                    if (fieldId === 'Cloud_Service_Connectivity__c' && hideElement) {
                        hideElement.insertAdjacentElement('beforebegin', customContainer);
                    } else {
                        parentRow.insertAdjacentElement('afterend', customContainer);
                    }
                }
    
                // Observe label changes for translations
                let labelText = getSafeText(label);
                observeTextChange(label, (translatedLabelText) => {
                    if (translatedLabelText && translatedLabelText !== labelText) {
                        labelText = translatedLabelText;
                    }
    
                    // Render the custom dropdown with the updated label and options
                    ReactDOM.render(
                        <CustomDropdown
                            setCustomDropdownValue={setCustomDropdownValue}
                            options={optionsProps}
                            fieldId={fieldId}
                            label={labelText}
                            formId={formId}
                            glossary={glossary}
                            lang={lang}
                        />,
                        customContainer
                    );
                });
    
                // Initial render of the custom dropdown
                ReactDOM.render(
                    <CustomDropdown
                        setCustomDropdownValue={setCustomDropdownValue}
                        options={optionsProps}
                        fieldId={fieldId}
                        label={labelText}
                        formId={formId}
                    />,
                    customContainer
                );
            }
        });
    
        setAreDropdownFieldsReplaced(!areDropdownFieldsReplaced);
    };
    

const cleanUpCustomDropdowns = () => {
    const customDropdownContainers = document.querySelectorAll('.custom-selector-container');

    customDropdownContainers.forEach(container => {
        const fieldId = container.id.replace('custom-selector-', '');
        if(!fieldId) return
        const originalSelect = document.querySelector(`select#${fieldId}`);

        // If the original <select> is not found, remove the custom container
        if (!originalSelect) {
            container.remove();
        }
    });
};

// Function to observe changes in the form and clean up if necessary
const setupFormObserver = () => {
    const formElement = document.querySelector('.register-lead form');

    if (!formElement) return;

    // Create a MutationObserver to observe changes in the form
    const observer = new MutationObserver(mutations => {
        let needsCleanup = false;

        // Check if any child nodes were added or removed
        mutations.forEach(mutation => {
            if (mutation.type === 'childList') {
                needsCleanup = true;
            }
        });

        // If changes were detected, clean up orphaned custom dropdowns
        if (needsCleanup) {
            cleanUpCustomDropdowns();
        }
    });

    // Start observing the form for child node changes
    observer.observe(formElement, { childList: true, subtree: true });

    // Perform initial cleanup when the observer is first set up
    cleanUpCustomDropdowns();
};

// Initialize the observer in useEffect
useEffect(() => {
    setupFormObserver();
}, []);

    

    useEffect(() => {
        if (formRef.current && glossary.length > 0) {
            const labels = document.querySelectorAll("form label");
            const buttons = document.querySelectorAll("form button");
            const options = document.querySelectorAll("form select option");
            const span = document.querySelectorAll("form div.mktoHtmlText span>span");
            const span2 = document.querySelectorAll("form div.mktoHtmlText");
            const strong = document.querySelectorAll("form strong");
            const input = document.querySelectorAll("form input");
            const textArea = document.querySelectorAll("form textarea");
            translateElements(labels, glossary, lang);
            translateElements(buttons, glossary, lang);
            translateElements(options, glossary, lang);
            translateElements(span, glossary, lang);
            translateElements(span2, glossary, lang);
            translateElements(strong, glossary, lang);
            translatePlaceholders(textArea, glossary, lang);
            translatePlaceholders(input, glossary, lang)
        }
    }, [glossary, lang, areDropdownFieldsReplaced]);



    const handleCustomChangeLogic = (mktoRows, formWidth, glossary, lang) => {
        let fields = [];
        let checkbox = document.querySelector('input[type="checkbox"]');

        fields.push(document.querySelectorAll('form .mktoField'));

        // Add event listener to the checkbox for change event
        checkbox?.addEventListener('change', function() {
            enableMarketoButton(formId, event, checkbox.checked);
        });

        // Run other functions to update rows and add event listeners
        updateMktoRows(mktoRows, formWidth);
        handleAddEventListener();
        handleAddHoverEffect(mktoRows);
        handleFocus(fields, glossary, lang);
        replaceDropdownFields(mktoRows);
    }

    function fetchDataFunction(props, glossary, lang){
        // let id = marketoScriptId + "_" + formId;
        // let form = typeof  window !== 'undefined' ? document.querySelector('#facility-marketo form') : '';

        useEffect(() => {
            if (!document.getElementById(marketoScriptId)) {
            // if (!document.getElementById(id)) {
            // if (!!document.getElementById(id)) {
            // if (!!document.getElementById(id) && form?.children?.length === 0) {
                loadScript();
            } else {
                setIsLoaded(true);
            }
        }, []);

        useEffect(() => {
            if (typeof window !== 'undefined') {
                (isLoaded && glossary) &&
                window.MktoForms2?.loadForm('//' + formUrl, formToken, formId, function(form){
                    formRef.current = form;
                    let formHeader;
                    let formHeaderSubtitle;

                    let removeClass = document.querySelectorAll("form .mktoButtonRow .mktoButtonWrap");
                    removeClass.forEach(child => {
                        child.classList.remove('mktoSimple');
                    })

                    if(!ctaPopUp){
                        formHeader = document.querySelector(".form-header .title");
                    }else {
                        formHeader = document.querySelector(".form-header .cta-title");
                    }

                    if(formHeader){
                        formHeader.innerHTML = props.marketo_header_text ?
                            props.marketo_header_text : props.field_marketo_header_text?.length !== 0 ? isItUndefined(props.field_marketo_header_text)
                                : "";
                    }

                    if(!ctaPopUp){
                        formHeaderSubtitle = document.querySelector(".form-header .subtitle");
                    }else {
                        formHeaderSubtitle = document.querySelector(".form-header .cta-subtitle");
                    }

                    if(formHeaderSubtitle){
                        formHeaderSubtitle.innerHTML = props.marketo_subtitle_text ?
                            props.marketo_subtitle_text : props.field_marketo_subtitle_text?.length !== 0 ? isItUndefined(props.field_marketo_subtitle_text)
                                : "";

                        if(formHeaderSubtitle.innerHTML.length === 0){
                            formHeaderSubtitle.style.display = 'none';
                        }
                    }

                    if(formHeader?.innerHTML.length === 0 && formHeaderSubtitle?.innerHTML.length === 0){
                        if(!ctaPopUp){
                            document.querySelector(".form-header").style.display = 'none';
                        }
                        let width = window.innerWidth;

                        if(width <= 992){
                            document.querySelector("form").style.paddingTop = '30px';
                        }
                    }

                    let labels = document.querySelectorAll("form label");
                    let buttons = document.querySelectorAll("form button");
                    let options = document.querySelectorAll("form select option");
                    let span = document.querySelectorAll("form div.mktoHtmlText span>span");
                    let span2 = document.querySelectorAll("form div.mktoHtmlText");
                    let strong = document.querySelectorAll("form strong");

                    let select = document.querySelectorAll("form select");
                    let textarea = document.querySelectorAll("form textarea");
                    let checkbox = document.querySelectorAll("form .mktoCheckboxList label");

                    // REGISTER A LEAD V2
                    if(isItRegisterLead){
                        const mktoRows = document.querySelectorAll("form .mktoFormRow");
                        const firstForm = document.querySelector('#register-lead form');
                        let formWidth = 0;

                        if (firstForm) {
                            formWidth = firstForm.offsetWidth;
                        }

                        updateMktoRows(mktoRows, formWidth);
                        updatePrivacyText(mktoRows);
                        handleAddHoverEffect(mktoRows);
                        replaceDropdownFields(mktoRows);

                        // document.getElementById('KR_Partner_Type__c')?.addEventListener('change', function() {
                        //
                        //
                        //     let fields = [];
                        //     let checkbox = document.querySelector('input[type="checkbox"]');
                        //
                        //     fields.push(document.querySelectorAll('form .mktoField'));
                        //     checkbox.addEventListener('change', function() {
                        //         enableMarketoButton(formId, event, checkbox.checked);
                        //     });
                        //     updateMktoRows(mktoRows, formWidth);
                        //     handleAddEventListener();
                        //     handleAddHoverEffect(mktoRows);
                        //     handleFocus(fields, glossary, lang);
                        //     replaceDropdownFields(mktoRows);
                        // });
                    }
                    if(isItContactUs){
                        let mktoRows2 = document.querySelectorAll("form .mktoFormRow");
                        let contactUsForm = document.querySelector('#contact-us-form form');
                        let formWidth2 = 0;

                        if (contactUsForm) {
                            formWidth2 = contactUsForm.offsetWidth;
                        }

                        updateMktoRows(mktoRows2, formWidth2);
                        updatePrivacyText(mktoRows2);
                        handleAddHoverEffect(mktoRows2);
                        replaceDropdownFields(mktoRows2);

                        // document.getElementById('KR_Partner_Type__c')?.addEventListener('change', function() {
                        //
                        //
                        //     let fields = [];
                        //     let checkbox = document.querySelector('input[type="checkbox"]');
                        //
                        //     fields.push(document.querySelectorAll('form .mktoField'));
                        //     checkbox.addEventListener('change', function() {
                        //         enableMarketoButton(formId, event, checkbox.checked);
                        //     });
                        //     updateMktoRows(mktoRows, formWidth);
                        //     handleAddEventListener();
                        //     handleAddHoverEffect(mktoRows);
                        //     handleFocus(fields, glossary, lang);
                        //     replaceDropdownFields(mktoRows);
                        // });
                    }

                    let downloadButton = document.querySelector("form[id*=" + CSS.escape(formId) + "] .mktoButton");
                    if(downloadButtonText && downloadButton){
                        downloadButton.innerHTML = downloadButtonText;
                    }

                    select.forEach((el) => {
                        let elem = el.parentElement.firstChild;
                        elem.classList.add('active');
                    });

                    // textarea.forEach((el) => {
                    //     let elem = el.parentElement.firstChild;
                    //     elem.classList.add('active');
                    // });

                    checkbox.forEach((el) => {
                        el.classList.add('active');
                    });

                    translateElements(labels, glossary, lang);
                    translateElements(buttons, glossary, lang);
                    translateElements(options, glossary, lang);
                    translateElements(span, glossary, lang);
                    translateElements(span2, glossary, lang);
                    translateElements(strong, glossary, lang);

                    let fields = [];
                    fields.push(document.querySelectorAll('form .mktoField'));
                    handleAddEventListener();

                    // populate form from cookie data
                    if(marketoCookie){
                        autoPopulateAddActive(fields)

                        if(form.submittable()){

                            if(buttons.length === 0){
                                return;
                            }

                            buttons[0].classList.add('active')
                        }
                    }

                    if(!form){
                        return;
                    }

                    if(marketoCookie){
                        delete marketoCookie.formid;
                    }

                    form.vals(marketoCookie);

                    handleFocus(fields, glossary, lang);

                    // SAME EMAIL CHECK - REMOVE THIS BLOCK

                    var formIdInput = document.querySelector('input[name="formid"]');
                    var formIdValue = formIdInput ? formIdInput.value : null;                                                                                                
                    
                    if (formIdValue === "2813" || formIdValue === "2926") {
                        form.onValidate(function (builtInValid) {
                            var partnerEmailInput = document.querySelector('[name="Partner_Email__c"]');
                            var emailInput = document.querySelector('[name="Email"]');
                            
                            if (partnerEmailInput && emailInput) {
                                // Kreiranje mktoError diva za Email polje
                                var emailErrorDiv = document.createElement('div');
                                emailErrorDiv.classList.add('mktoError');
                                var emailErrorMsgDiv = document.createElement('div');
                                emailErrorMsgDiv.classList.add('mktoErrorMsg');
                                emailErrorMsgDiv.textContent = isItUndefined(global.field_marketo_email_error) ? `${(isMobile(globalWidth) || isTablet(globalWidth)) ? isItUndefined(global.field_marketo_email_error).split('.')[0] + "." : isItUndefined(global.field_marketo_email_error)}` : isMobile(globalWidth) || isTablet(globalWidth) ? 'Emails cannot match.' : 'Emails cannot match. Provide the customer contact information, including email, in the corresponding fields.'
                                emailErrorMsgDiv.style.display = 'none'; 
                                emailErrorDiv.appendChild(emailErrorMsgDiv);
                    
                                // Kreiranje mktoError diva za Partner_Email__c polje
                                var partnerEmailErrorDiv = document.createElement('div');
                                partnerEmailErrorDiv.classList.add('mktoError');
                                var partnerEmailErrorMsgDiv = document.createElement('div');
                                partnerEmailErrorMsgDiv.classList.add('mktoErrorMsg');
                                partnerEmailErrorMsgDiv.textContent = isItUndefined(global.field_marketo_email_error) ? `${(isMobile(globalWidth) || isTablet(globalWidth)) ? isItUndefined(global.field_marketo_email_error).split('.')[0] + "." : isItUndefined(global.field_marketo_email_error)}` : isMobile(globalWidth) || isTablet(globalWidth) ? 'Emails cannot match.' : 'Emails cannot match. Provide the customer contact information, including email, in the corresponding fields.'
                                partnerEmailErrorMsgDiv.style.display = 'none';
                                partnerEmailErrorDiv.appendChild(partnerEmailErrorMsgDiv);
                    
                                // Dodavanje kreiranih divova u parent elemente input polja
                                if (!emailInput.parentNode.querySelector('.mktoError')) {
                                    emailInput.parentNode.appendChild(emailErrorDiv);
                                }
                                if (!partnerEmailInput.parentNode.querySelector('.mktoError')) {
                                    partnerEmailInput.parentNode.appendChild(partnerEmailErrorDiv);
                                }
                    
                                // Dodavanje klase 'removeError' i uklanjanje 'mktoFilled' na parentu od parenta
                                var emailParentOfParent = emailInput.parentNode.parentNode;
                                var partnerEmailParentOfParent = partnerEmailInput.parentNode.parentNode;
                    
                                // Dodaj klasu removeError
                                emailParentOfParent.classList.add('removeError');
                                partnerEmailParentOfParent.classList.add('removeError');
                    
                                // Ukloni klasu mktoFilled ako postoji
                                emailParentOfParent.classList.remove('mktoFilled');
                                partnerEmailParentOfParent.classList.remove('mktoFilled');
                    
                                // Logika za prikazivanje greške
                                if (partnerEmailInput.value === emailInput.value) {
                                    emailErrorMsgDiv.style.cssText = 'display: block !important;';
                                    partnerEmailErrorMsgDiv.style.cssText = 'display: block !important;';
                                    var element = document.getElementById('mktoForm_' + formId);
                                    window.scrollTo({ top: element, behavior: 'smooth' });
                    
                                    form.submittable(false);
                                } else {
                                    emailParentOfParent.classList.remove('removeError');
                                    partnerEmailParentOfParent.classList.remove('removeError');
                                    emailParentOfParent.classList.add('mktoFilled');
                                    partnerEmailParentOfParent.classList.add('mktoFilled');
                                    emailErrorMsgDiv.style.cssText = 'display: none !important;';
                                    partnerEmailErrorMsgDiv.style.cssText = 'display: none !important;';
                                    form.submittable(true);
                                }
                            }
                        });
                    }
                    
                    

                    // SAME EMAIL CHECK - REMOVE THIS BLOCK

                    // console.log - onSuccess is for production, onSubmit for localhost
                    form.onSuccess(function(values, followUpUrl) {
                    // form.onSubmit(function(values, followUpUrl) {
                        let vals = form.vals();
                        if(vals?.Pref_Optin_Pending__c){
                            vals.Pref_Optin_Pending__c = 'no';
                        }
                        if(!ctaPopUp){
                            if(!alias){
                                props.data.setTrigger(false);
                                props.data.setIsSubmitted(true);
                                return false;
                            }

                            if(children.data){
                                children.data.setTrigger(false);
                                children.data.setIsSubmitted(true);
                                return false;
                            }
                        }
                        else if(ctaPopUp){
                            children.data.setIsSubmitted(true);
                        }
                        if(isTemplateInfo){
                            seIsFormSubmitted(true);
                        }

                        setIsSubmittedLocal(true);
                        setFirstName(vals.FirstName);
                        setEmail(vals.Email)

                        const today = new Date();
                        const tomorrow = new Date(today);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        let date = Number(isItUndefined(global.field_marketo_cookie_expiration)) !== 0 ? new Date(new Date().getTime() + (Number(isItUndefined(global.field_marketo_cookie_expiration)) + 1) * 60 * 60 * 1000) : tomorrow;

                        setCookie('marketoissetondlr', 'true', { expires: date })
                        setCookie('marketovals', vals, {expires: date});
                        // setCookie('newMarketoVals', vals, {expires: date});

                        localStorage.setItem('newMarketoVals', JSON.stringify(vals));

                        let obj = {
                            'name': 'marketovals',
                            'value': vals
                        }

                        dispatch(setMarketoCookieRedux(obj))

                        let anchorFacility = document.getElementById('facility-marketo')?.offsetTop;
                        let anchorTemplate = document.getElementById('template-marketo')?.offsetTop;
                        let anchorContactInfoTemplate = document.getElementById('template-contact-info')?.offsetTop;

                        if(anchorFacility){
                            window.scrollTo({ top: anchorFacility - 200, behavior: 'smooth'});
                        }else if(anchorTemplate){
                            window.scrollTo({ top: anchorTemplate - 100, behavior: 'smooth'});
                        }else if(anchorContactInfoTemplate){
                            window.scrollTo({ top: anchorContactInfoTemplate - 100, behavior: 'smooth'});
                        }

                        fields[0].forEach(item => {
                            // item.removeEventListener("change", handleChange);
                            item.removeEventListener("input", handleChange);
                        });

                        return false;
                    });
                });
            }

        }, [isLoaded, glossary]);

        const loadScript = () => {
            var s = document.createElement('script');
            s.id = marketoScriptId;
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//app-sj11.marketo.com/js/forms2/js/forms2.min.js';
            s.onreadystatechange = function() {
                if (this.readyState === 'complete' || this.readyState === 'loaded') {
                    setIsLoaded(true);
                }
            };
            s.onload = () => setIsLoaded(true);
            document.getElementsByTagName('head')[0].appendChild(s);
        };

        return isLoaded;
    }

    function addActiveClassToNewElements(){
        let select = document.querySelectorAll(".marketo-form select");
        let textarea = document.querySelectorAll(".marketo-form textarea");
        let checkbox = document.querySelectorAll(".marketo-form .mktoCheckboxList label");

        select.forEach((el) => {
            el.addEventListener('change', (e) => handleClick(e, 'United States'));
            let elem = el.parentElement.firstChild;
            elem.classList.add('active');
        });

        textarea.forEach((el) => {
            let elem = el.parentElement.firstChild;
            elem.classList.add('active');
        });

        checkbox.forEach((el) => {
            el.classList.add('active');
        });
    }

    function handleClick(e, country){
        let result = '';
        for(let item of e.target){
            if(item.innerText === country){
                result = item.innerText;
            }
        }

        addActiveClassToNewElements();
    }

    function addFirstNameAutomatically(data){
        if(!data){
            return;
        }

        let templateTitle = '';

        if(document.querySelector('.template-title h1')){
            templateTitle = document.querySelector('.template-title h1').innerHTML;
        }

        data = data.replace("{first_name}", firstName !== undefined ? firstName : "");
        data = data.replace("{H1}", templateTitle !== undefined ? templateTitle : "");

        return data;
        // return data.replace("{first_name}", firstName !== undefined ? firstName : "");
    }

    function addFirstNameAutomaticallyKnownUser(data){
        let firstNameLocal = '';
        let firstNameLocalNew = '';

        if(!data){
            return;
        }

        if(getCookie('marketovals')){
            firstNameLocal = JSON.parse(getCookie('marketovals'));
        }

        // if(getCookie('newMarketoVals')){
        //     firstNameLocalNew = JSON.parse(getCookie('newMarketoVals'));
        // }

        if(localStorage.getItem('newMarketoVals')){
            firstNameLocalNew = JSON.parse(localStorage.getItem('newMarketoVals'));   
        }

        // let templateTitle = document.querySelector('.template-title h1').innerHTML;

        let templateTitle = '';

        if(document.querySelector('.template-title h1')){
            templateTitle = document.querySelector('.template-title h1').innerHTML;
        }

        // data = data.replace("{first_name}", firstNameLocal.FirstName !== undefined ? firstNameLocal.FirstName : newMarketoVals.FirstName !== undefined ? newMarketoVals.FirstName : "")
        const firstNameToReplace = firstNameLocal.FirstName ?? firstNameLocalNew.FirstName ?? "";

        data = data.replace("{first_name}", firstNameToReplace);
        data = data.replace("{H1}", templateTitle !== undefined ? templateTitle : "");

        // return data.replace("{first_name}", firstNameLocal.FirstName !== undefined ? firstNameLocal.FirstName : "");
        return data;
    }

    function downloadAndEmailFile(hiddenSubmit){
        // let link = "mailto:" + email;
        let file;

        // let formId = isItUndefined(children.field_marketo_form_ids);
        // let formToken = isItUndefined(global.field_marketo_form_token);
        // let formUrl = isItUndefined(global.field_marketo_form_url);

        // if(checked){
        //     window.location.href = link;
        // }

        if(children?.field_marketo_download_file[0]?.value){
            file = children?.field_marketo_download_file[0]?.value
        }

        if(children?.field_marketo_file_url[0]?.value){
            file = children?.field_marketo_file_url[0]?.value;
        }

        if(hiddenSubmit){
            window.MktoForms2.loadForm('//' + formUrl, formToken, formId, function(form){
                form.setValues(marketoCookie)

                if(form.submittable()){
                    form.submit();
                }
            });
        }

        children.data?.setTrigger(false)

        // window.open(file, '_blank');
        if(file){
            window.open(file, '_blank');
        }
    }

    function showDownloadButtonAndCheckbox(text){
        let file;

        if(children?.field_marketo_download_file[0]?.value){
            file = children?.field_marketo_download_file[0]?.value
        }

        if(children?.field_marketo_file_url[0]?.value){
            file = children?.field_marketo_file_url[0]?.value;
        }

        return !!(file && isItUndefined(text));
    }

    function renderSuccessMessage(){
        return(
            <div className={'success-message-header'}>
                <div className={'facility-marketo-success'}>
                    <h1 dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_title_text))) }} />
                    <h3 className={'subtitle'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_subtitle_t))) }} />
                    <div className={'text'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_body_text))) }} />
                    {
                        showDownloadButtonAndCheckbox(children.field_marketo_message_button_tex) &&
                        <div className={'div-marketo-link downloadButton'} onClick={() => downloadAndEmailFile()} dangerouslySetInnerHTML={{ __html: addFirstNameAutomatically(isItUndefined(children.field_marketo_message_button_tex)) }} />
                    }
                    <div className={'privacy-text'}>
                        <p dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_privacy_text))) }} />
                    </div>
                </div>
            </div>
        );
    }

    function renderKnownUserMessage(){
        return(
            <div className={'success-message-header'}>
                <div className={'facility-marketo-success'}>
                    <h1 dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_title))) }} />
                    <h3 className={'subtitle'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_subtitl))) }} />
                    <div className={'text'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_message)) )}} />
                    {
                        showDownloadButtonAndCheckbox(children.field_marketo_known_user_button_) &&
                        <div className={'div-marketo-link downloadButton'} onClick={() => downloadAndEmailFile(true)} dangerouslySetInnerHTML={{ __html: addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_button_)) }} />
                    }
                    <div className={'privacy-text'}>
                        <p dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_privacy))) }} />
                    </div>
                </div>
            </div>
        );
    }

    function autoPopulate(){
        return !(children.field_marketo_download_file.length !== 0 || children.field_marketo_file_url.length !== 0);
    }

    function renderMessage(){
        if(isSubmittedLocal){
            if(setShowDownloadItems !== undefined){
                setShowDownloadItems(true)
            }
            {/* DXP-3108 - REMOVE FOR PRODUCTION */}
            return renderSuccessMessage()
            // return videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux === false ? renderSuccessMessage() : '';

            // if (isItGated) {
            //     const videoState = videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux;
            
            //     if (videoState === false || (typeof videoState !== 'boolean' && Object.keys(videoState).length === 0)) {
            //         return renderSuccessMessage();
            //     }
            
            //     return '';
            // } else {
            //     return renderSuccessMessage();
            // }
        }else if(isSubmitted){
            if(autoPopulate() === false){
                if(setShowDownloadItems !== undefined){
                    setShowDownloadItems(true)
                }
                {/* DXP-3108 - REMOVE FOR PRODUCTION */}
                return renderKnownUserMessage()
                // return videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux === false ? renderKnownUserMessage() : '';

                // if (isItGated) {
                //     const videoState = videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux;
                
                //     if (videoState === false || (typeof videoState !== 'boolean' && Object.keys(videoState).length === 0)) {
                //         return renderKnownUserMessage();
                //     }
                
                //     return '';
                // } else {
                //     return renderKnownUserMessage();
                // }
            }
        }

        return '';
    }

    function showForm(){
        if(isSubmittedLocal){
            return 'none';
        }

        if(isSubmitted){
            if(!autoPopulate()){
                return 'none';
            }
        }

        return '';
    }

    fetchDataFunction(children, glossary, lang);

    return (
        <>
            {
                !ctaPopUp && (!isSubmittedLocal && !isSubmitted) &&
                <div className={'form-header modal-header'}>
                    <div>
                        <p className={'title'} />
                        <p className={'subtitle'} />
                    </div>
                    {
                        ctaPopUp && close !== undefined && <div className={'cancel'} onClick={() => {
                            close()
                        }}>×</div>
                    }
                </div>
            }
            {/* {
                // showEmailError &&
                <div className="email-error">
                    {isItUndefined(global.field_marketo_email_error)  || 'Emails cannot match. Provide the customer contact information, including email, in the corresponding fields.'}
                </div>
            } */}
            {
                ctaPopUp && (!isSubmittedLocal || !isSubmitted) &&
                <div className={'form-header modal-header'}>
                    <div>
                        <p className={'cta-title'} />
                        <p className={'cta-subtitle'} />
                    </div>
                    {
                        ctaPopUp && close !== undefined && <div className={'cancel'} onClick={() => {
                            close()
                        }}>×</div>
                }
                </div>
            }
            {
                close !== undefined && !ctaPopUp &&
                <div className={'cancel'} onClick={() => {
                    close()
                }}>×</div>
            }
            { renderMessage() }
            <form style={{display: showForm()} } id={'mktoForm_' +  formId } />
        </>
    )
}
